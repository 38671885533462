import RatesMap from '@pretto/bricks/website/rates/components/RatesMap'
import RatesRowRegionsText from '@pretto/bricks/website/rates/components/RatesRowRegionsText'
import { components, defaultOverrides } from '@pretto/bricks/website/utility/ContentBlock'

import RatesSelectController from '@pretto/website/src/components/Rates/RatesSelectController'
import { BlocRow } from '@pretto/website/src/templates/blocs/components/BlocRow'
import { ControllerProps } from '@pretto/website/src/templates/blocs/types/ControllerProps.type'
import { RatesPayload } from '@pretto/website/src/templates/blocs/types/RatesPayload.type'
import { ratesRegion } from '@pretto/website/src/templates/tauxImmobilier/lib/ratesRegion'

type ProfilRowProps = {
  regionsRowContent: string
  regionsRowDuration?: string
  regionsRowIsReversed?: boolean
  regionsRowMortgagors?: string
  rates: RatesPayload
  regionsRowRegion?: string
  regionsRowTitle: string
}

export const RegionsRow: React.FC<ProfilRowProps> = ({
  regionsRowContent,
  regionsRowDuration,
  regionsRowIsReversed,
  rates,
  regionsRowTitle,
  ...props
}) => {
  const graph = {
    component: RatesMap,
    props: (props: ControllerProps) => ratesRegion(rates.marketRatesRegionLatest, props),
  }

  const rowProps = {
    overrides: { ...defaultOverrides, Cta: { component: components.Cta, props: { align: 'left' } } },
    content: regionsRowContent,
    title: regionsRowTitle,
    ...props,
  }

  return (
    <BlocRow isReversed={regionsRowIsReversed}>
      <RatesSelectController defaultDuration={regionsRowDuration ? parseInt(regionsRowDuration, 10) : undefined}>
        {props => <RatesRowRegionsText {...rowProps} {...props(graph)} />}
      </RatesSelectController>
    </BlocRow>
  )
}
